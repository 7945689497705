@import "../scss/variables";

.round-close-button {
  border-radius: 50%;
  background-color: rgba(0,0,0,.25);
  vertical-align: middle;
  transition: background-color 0.2s ease;
  border: none;
  outline: none;
  padding: 0 3px;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    background-color: $danger-bg-color;
  }

  > svg-icon {
    width: 14px;
    height: 14px;
    color: #fff;
    vertical-align: middle;
  }
}